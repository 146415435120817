export default ({
  img,
  title,
  subtitle,
  description,
  url,
}: {
  img: string;
  title: string;
  subtitle: string;
  description: string;
  url: string;
}) => {
  return (
    <div className="flex items-start space-x-4 lg:space-x-6">
      <img src={img} className="w-[120px] lg:w-[200px] xl:w-[220px]" />
      <div className="space-y-4 lg:space-y-4">
        <div className="leading-5 h-auto lg:h-[88px]">
          <h1
            className={`${
              title === "Avitex"
                ? "text-3xl lg:text-5xl font-extrabold"
                : "text-2xl lg:text-4xl font-bold"
            }`}
          >
            {title}
          </h1>
          <div
            className="text-sm lg:text-base"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></div>
        </div>

        <div
          className="h-auto lg:h-[72px] xl:h-[40px]"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>

        <button
          className="bg-white text-xs lg:text-sm xl:text-base font-bold px-4 py-2"
          onClick={() => window.open(url)}
        >
          Pelajari Selengkapnya
        </button>
      </div>
    </div>
  );
};
