import { useAppSelector } from "../hooks/useAppSelector";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { AiOutlineRightCircle, AiOutlineLeftCircle } from "react-icons/ai";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useCallback, useRef } from "react";

export default ({ onSelectVideo }: { onSelectVideo: (e: string) => void }) => {
  const global = useAppSelector((state) => state.global);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slideNext();
  }, []);

  return (
    <section id="makeover">
      <h1 className="bg-[#f5c049] text-[#303030] text-3xl lg:text-6xl w-fit p-4 rounded-t-3xl m-auto">
        Makeover
      </h1>
      <h1 className="font-go-mocha bg-[#303030] text-3xl lg:text-6xl text-[#f5c049] text-center p-6 lg:p-8">
        #RuangEksplorasi{" "}
        <span className="font-open-sans text-2xl lg:text-4xl">
          By Avian Brands
        </span>
      </h1>

      <div className="py-10 px-10 lg:px-0 lg:py-20 bg-cover">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          ref={sliderRef}
          spaceBetween={80}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            720: {
              spaceBetween: 80,
              slidesPerView: 2.5,
              slidesOffsetBefore: 80,
            },
          }}
        >
          {global.playlistMakeover.map((e, i) => (
            <SwiperSlide key={i}>
              <div className="space-y-4 lg:space-y-8">
                <div
                  onClick={() => {
                    onSelectVideo(e.resourceId.videoId);
                  }}
                  className="bg-cover bg-left flex items-center justify-center rounded-3xl h-[240px] lg:h-[360px] cursor-pointer"
                  style={{
                    backgroundImage: `url("${
                      e.thumbnails.maxres
                        ? e.thumbnails.maxres.url
                        : e.thumbnails.high.url
                    }")`,
                  }}
                >
                  <img
                    src="/assets/play.png"
                    className="opacity-50 h-[120px] lg:h-[180px]"
                  />
                </div>
                <div
                  className="text-xl lg:text-3xl text-center max-w-[480px] m-auto"
                  dangerouslySetInnerHTML={{ __html: e.title }}
                ></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div
          className="absolute right-[150px] mt-[-47px] text-5xl cursor-pointer z-10 hidden lg:block"
          onClick={handlePrev}
        >
          <AiOutlineLeftCircle />
        </div>
        <div
          className="absolute right-[80px] mt-[-47px] text-5xl cursor-pointer z-10 hidden lg:block"
          onClick={handleNext}
        >
          <AiOutlineRightCircle />
        </div>
      </div>
    </section>
  );
};
