import { useAppSelector } from "../hooks/useAppSelector";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { AiOutlineRightCircle, AiOutlineLeftCircle } from "react-icons/ai";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useCallback, useRef } from "react";

export default () => {
  const global = useAppSelector((state) => state.global);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slideNext();
  }, []);

  return (
    <section className="py-10 pb-0 lg:py-20 bg-[#78cec6]" id="inspirasi-diy">
      <h1 className="bg-[#f5c049] text-[#303030] text-3xl lg:text-6xl w-fit p-4 rounded-t-3xl m-auto">
        Inspirasi
      </h1>
      <h1 className="font-go-mocha bg-[#e23122] text-3xl lg:text-6xl text-[#FFF] text-center p-6 lg:p-8">
        Aktivitas DIY Dirumah Pakai Cat
      </h1>

      <div className="py-10 px-10 lg:px-0 lg:py-20 bg-cover">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          ref={sliderRef}
          spaceBetween={80}
          slidesPerView={1}
          pagination={{ clickable: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            720: {
              spaceBetween: 80,
              slidesPerView: global.inspiration.length >= 3 ? 2.5 : 2,
              slidesOffsetBefore: 80,
            },
          }}
        >
          {global.inspiration.map((e, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="space-y-12">
                  {e.map((f, j) => {
                    return (
                      <div
                        key={j}
                        className="space-y-8 cursor-pointer"
                        onClick={() => window.open(`/article/${f.url}`)}
                      >
                        <div
                          className="bg-cover flex items-center justify-center rounded-3xl h-[240px] lg:h-[360px]"
                          style={{
                            backgroundImage: `url("${f.img}")`,
                          }}
                        ></div>
                        <div className="text-xl lg:text-2xl text-center">
                          <h3 className="font-bold line-clamp-2">{f.title}</h3>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div
          className="absolute right-[150px] mt-[-47px] text-5xl cursor-pointer z-10 hidden lg:block"
          onClick={handlePrev}
        >
          <AiOutlineLeftCircle />
        </div>
        <div
          className="absolute right-[80px] mt-[-47px] text-5xl cursor-pointer z-10 hidden lg:block"
          onClick={handleNext}
        >
          <AiOutlineRightCircle />
        </div>
      </div>
    </section>
  );
};
