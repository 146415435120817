import { useEffect, useState } from "react";

// Import Swiper styles
import "swiper/css";
import { useAppSelector } from "../hooks/useAppSelector";
import { useAppDispatch } from "../hooks/useAppDispatch";
import axios from "axios";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import ArtikelLainnya from "../components/ArtikelLainnya";
import Modal from "../components/Modal";

const STRAPI_API_URL = process.env.REACT_APP_STRAPI_API_URL;
const STRAPI_TOKEN = process.env.REACT_APP_STRAPI_TOKEN;

export default () => {
  const { url } = useParams();
  const global = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [article, setarticle] = useState(null as any);
  const [articles, setarticles] = useState([] as any);
  const [showBuyModal, setshowBuyModal] = useState(false);

  useEffect(() => {
    Promise.all([
      axios.get(
        `${STRAPI_API_URL}/api/inspirasis?populate=*&filters[path][$eq]=${encodeURIComponent(
          url as string
        )}`,
        {
          headers: {
            Authorization: `Bearer ${STRAPI_TOKEN}`,
          },
        }
      ),
      axios.get(
        `${STRAPI_API_URL}/api/inspirasis?populate=*&sort=date:desc&fields[0]=title&fields[1]=path&fields[2]=date`,
        {
          headers: {
            Authorization: `Bearer ${STRAPI_TOKEN}`,
          },
        }
      ),
    ]).then((res) => {
      let { data } = res[0].data;

      if (data.length > 0) {
        const { content, date, title, cover } = data[0].attributes;
        setarticle({
          content,
          date,
          title,
          cover: STRAPI_API_URL + cover.data.attributes.url,
        });
      } else {
        window.location.href = "/error-page";
      }

      data = res[1].data.data;
      const _articles = data.map((e: any) => {
        const { date, title, cover, path } = e.attributes;
        return {
          date,
          title,
          url: path,
          cover: STRAPI_API_URL + cover.data.attributes.url,
        };
      });

      setarticles(_articles);
      setLoading(false);
    });
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="font-open-sans">
      {showBuyModal && (
        <Modal onClose={() => setshowBuyModal(false)}>
          <div className="bg-white w-full h-full flex p-12 gap-4 items-center">
            <div className="flex gap-8 flex-col md:flex-row">
              <div className="flex items-center flex-col md:flex-row">
                {/* <img src="/assets/logo-01.png" width={200} /> */}
                <img src="/assets/tokped-01.png" width={140} />
              </div>
              <div className="flex items-center self-center flex-col gap-2">
                <h3 className="font-bold text-[#303030] mb-4">Beli Sekarang</h3>

                <a
                  className="bg-[#368d2f] text-white p-2 px-4 text-xs cursor-pointer text-center w-full hover:bg-transparent hover:border-[#368d2f] hover:text-[#368d2f] border border-white"
                  href="https://www.tokopedia.com/avianjakarta/etalase/seri-disney"
                  target="_blank"
                >
                  Avian Brands Jakarta
                </a>

                <a
                  className="bg-[#f5c049] text-white p-2 px-4 text-xs cursor-pointer text-center w-full hover:bg-transparent hover:border-[#f5c049] hover:text-[#f5c049] border border-white"
                  href="https://www.tokopedia.com/aviansurabaya/etalase/seri-disney"
                  target="_blank"
                >
                  Avian Brands Surabaya
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <Navbar showBuyModal={() => setshowBuyModal(true)}></Navbar>
      <section className="p-10">
        <div className="max-w-[1024px] m-auto space-y-4">
          <div>{format(new Date(article.date), "dd MMMM yyyy")}</div>
          <h1 className="font-bold text-3xl">{article.title}</h1>
          <img src={article.cover} />
          <div
            className="leading-8 space-y-4 text-lg"
            dangerouslySetInnerHTML={{ __html: article.content }}
          ></div>

          <ArtikelLainnya articles={articles} />
        </div>
      </section>
    </div>
  );
};
