import { useEffect, useState } from "react";
import { BsList } from "react-icons/bs";

const Navbar = ({ showBuyModal }: { showBuyModal: () => void }) => {
  const [navColor, setnavColor] = useState("#fbde5657");
  const [navOpen, setnavOpen] = useState(false);

  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#FFF") : setnavColor("#fbde5657");
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  useEffect(() => {
    const handleChange = () => {
      setnavOpen((prev) => !prev);
    };
    window.addEventListener("hashchange", handleChange);
    return () => {
      window.removeEventListener("hashchange", handleChange);
    };
  }, []);

  return (
    // <nav
    //   className={`flex justify-between py-2 px-20 font-semibold text-sm sticky top-0 z-10`}
    //   style={{
    //     backgroundColor: navColor,
    //   }}
    // >
    //   <div>
    //     <img src="/assets/logo-01.png" className="h-[40px]" />
    //   </div>
    //   <div className="flex space-x-4 items-center">
    //     <a href="#special-package" className="cursor-pointer hover:drop-shadow">
    //       Special Package
    //     </a>
    //     <a href="#makeover" className="cursor-pointer hover:drop-shadow">
    //       Makeover
    //     </a>
    //     <a href="#inspirasi-diy" className="cursor-pointer hover:drop-shadow">
    //       Inspirasi DIY
    //     </a>
    //     <a href="#cat-seri-disney" className="cursor-pointer hover:drop-shadow">
    //       Cat Seri Disney
    //     </a>

    //     <button
    //       className="border-black p-2 px-4 text-xs cursor-pointer"
    //       style={{
    //         border: "1px solid black",
    //       }}
    //     >
    //       Beli Disini
    //     </button>
    //     <button
    //       className="bg-black text-white p-2 px-4 text-xs cursor-pointer"
    //       style={{
    //         border: "1px solid black",
    //       }}
    //     >
    //       Cari Toko
    //     </button>
    //   </div>
    // </nav>
    <nav
      className="bg-white border-gray-200 sticky top-0 z-20"
      style={{
        backgroundColor: navColor,
      }}
    >
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2 lg:p-4">
        <a href="/" className="flex items-center">
          <img src="/assets/logo-01.png" className="h-6 lg:h-8 mr-3" />
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={() => setnavOpen((prev) => !prev)}
        >
          <BsList className="text-3xl" />
        </button>
        <div
          className={`${navOpen ? "" : "hidden"} w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col items-center p-4 md:p-0 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0">
            <li>
              <a
                href="/#special-package"
                className="block py-2 pl-3 pr-4 text-black rounded md:bg-transparent md:text-black md:p-0 hover:drop-shadow"
                aria-current="page"
              >
                Special Package
              </a>
            </li>
            <li>
              <a
                href="/#makeover"
                className="hover:drop-shadow block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-black md:p-0"
              >
                Makeover
              </a>
            </li>
            <li>
              <a
                href="/#inspirasi-diy"
                className="hover:drop-shadow block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-black md:p-0"
              >
                Inspirasi DIY
              </a>
            </li>
            <li>
              <a
                href="/#cat-seri-disney"
                className="hover:drop-shadow block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-black md:p-0"
              >
                Cat Seri Disney
              </a>
            </li>

            <li className="space-x-2 mt-4 lg:mt-0">
              <button
                className="border-black p-2 px-4 text-xs cursor-pointer"
                style={{
                  border: "1px solid black",
                }}
                onClick={showBuyModal}
              >
                Beli Disini
              </button>
              <button
                className="bg-black text-white p-2 px-4 text-xs cursor-pointer"
                style={{
                  border: "1px solid black",
                }}
                onClick={() => window.open("https://avianbrands.com/store")}
              >
                Cari Toko
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
