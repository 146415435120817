import { ReactNode, useState } from "react";

export default ({
  children,
  onClose,
}: {
  children: ReactNode;
  onClose: () => void;
}) => {
  return (
    <div
      className="modal fixed left-0 top-0 z-20 w-screen h-screen bg-[#ffffff70] m-auto flex items-center justify-center"
      onClick={onClose}
    >
      <div className="modal-content">
        {/* <span className="close">&times;</span> */}
        {children}
      </div>
    </div>
  );
};
