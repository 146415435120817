import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TYoutube = {
  title: string;
  description: string;
  thumbnails: {
    maxres: {
      url: string;
    };
    high: {
      url: string;
    };
  };
  resourceId: {
    videoId: string;
  };
};

export type TProduct = {
  title: string;
  subtitle: string;
  description: string;
  img: string;
  url: string;
};

export type TInspiration = {
  title: string;
  img: string;
  url: string;
  date: Date;
};

type globalState = {
  playlistDisney: Array<TYoutube>;
  playlistMakeover: Array<TYoutube>;
  catTembok: Array<TProduct>;
  catDekoratif: Array<TProduct>;
  inspiration: Array<Array<TInspiration>>;
};

const initialState: globalState = {
  playlistDisney: [],
  playlistMakeover: [],
  catTembok: [],
  catDekoratif: [],
  inspiration: [],
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    update(state, action: PayloadAction<globalState>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { update } = globalSlice.actions;
export default globalSlice.reducer;
