import { useAppSelector } from "../hooks/useAppSelector";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { AiOutlineRightCircle, AiOutlineLeftCircle } from "react-icons/ai";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useCallback, useRef } from "react";

export default ({ articles }: { articles: any[] }) => {
  const global = useAppSelector((state) => state.global);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slideNext();
  }, []);

  return (
    <div className="!mt-10">
      <h1 className="font-go-mocha bg-[#303030] text-xl lg:text-3xl text-[#f5c049] text-center p-4 ">
        Artikel Lainnya
      </h1>

      <style>
        {`
          @media (min-width: 1024px) {
            .swiper-pagination {
              left: 0px !important;
              text-align: left !important;
            }
          }
        `}
      </style>

      <div className="py-10 px-0 lg:px-0 lg:pb-0 lg:py-10">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          ref={sliderRef}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            720: {
              spaceBetween: 80,
              slidesPerView: 2.5,
            },
          }}
        >
          {articles.map((e, i) => (
            <SwiperSlide key={i}>
              <div className="space-y-4 lg:space-y-8">
                <div
                  onClick={() => {
                    window.location.href = e.url;
                  }}
                  className="bg-cover bg-left flex items-center justify-center rounded-3xl h-[180px] lg:h-[240px] cursor-pointer"
                  style={{
                    backgroundImage: `url("${e.cover}")`,
                  }}
                ></div>
                <div
                  className="text-sm lg:text-xl text-center max-w-[480px] m-auto line-clamp-2"
                  dangerouslySetInnerHTML={{ __html: e.title }}
                ></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="absolute left-[865px] mt-[-47px] text-5xl cursor-pointer z-10 hidden lg:block"
          onClick={handlePrev}
          style={{
            left:
              1024 -
              159 +
              (window.innerWidth <= 1024
                ? 0
                : (window.innerWidth - (1024 - 98)) / 2),
          }}
        >
          <AiOutlineLeftCircle />
        </div>
        <div
          className="absolute left-[935px] mt-[-47px] text-5xl cursor-pointer z-10 hidden lg:block"
          style={{
            left:
              1024 -
              98 +
              (window.innerWidth <= 1024
                ? 0
                : (window.innerWidth - (1024 - 98)) / 2),
          }}
          onClick={handleNext}
        >
          <AiOutlineRightCircle />
        </div>
      </div>
    </div>
  );
};
