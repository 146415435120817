export default ({
  img,
  title,
  subtitle,
  description,
  url,
}: {
  img: string;
  title: string;
  subtitle: string;
  description: string;
  url: string;
}) => {
  return (
    <div className="flex items-start space-x-4 lg:space-x-0 xl:space-x-4">
      <img src={img} className="w-[120px] xl:w-[160px]" />
      <div className="space-y-4 xl:space-y-4">
        <div className="leading-3">
          <h1 className="text-2xl xl:text-4xl font-bold">{title}</h1>
          <p className="text-xl xl:text-3xl">{subtitle}</p>
        </div>
        <p className="h-auto lg:h-[80px] text-base xl:text-base leading-5">
          {description}
        </p>

        <button
          className="bg-white text-xs xl:text-base font-bold px-4 lg:px-3 xl:px-4 py-2"
          onClick={() => window.open(url)}
        >
          Pelajari Selengkapnya
        </button>
      </div>
    </div>
  );
};
