import { ReactNode, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { useAppSelector } from "../hooks/useAppSelector";
import { useAppDispatch } from "../hooks/useAppDispatch";
import axios from "axios";
import {
  TInspiration,
  TProduct,
  TYoutube,
  update,
} from "../redux/slicers/global-slice";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import CatDekoratif from "../components/CatDekoratif";
import CatTembok from "../components/CatTembok";
import Modal from "../components/Modal";
import IdeDekorasi from "../components/IdeDekorasi";
import Makeover from "../components/Makeover";
import Inspirasi from "../components/Inspirasi";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const STRAPI_API_URL = process.env.REACT_APP_STRAPI_API_URL;
const STRAPI_TOKEN = process.env.REACT_APP_STRAPI_TOKEN;

export default () => {
  const global = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const [showModal, setshowModal] = useState(false);
  const [showBuyModal, setshowBuyModal] = useState(false);
  const [loadingFrame, setloadingFrame] = useState(true);
  const [videoId, setvideoId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      // axios.get(`${BACKEND_API_URL}/v1/get-playlist-disney`),
      axios.get(
        `${STRAPI_API_URL}/api/playlist-disneys?populate=*&sort[0]=order:asc&sort[1]=id:desc`,
        {
          headers: {
            Authorization: `Bearer ${STRAPI_TOKEN}`,
          },
        }
      ),
      // axios.get(`${BACKEND_API_URL}/v1/get-playlist-disney`),
      axios.get(
        `${STRAPI_API_URL}/api/playlist-makeovers?populate=*&sort[0]=order:asc&sort[1]=id:desc`,
        {
          headers: {
            Authorization: `Bearer ${STRAPI_TOKEN}`,
          },
        }
      ),
      axios.get(`${STRAPI_API_URL}/api/produks?populate=*&sort[0]=id`, {
        headers: {
          Authorization: `Bearer ${STRAPI_TOKEN}`,
        },
      }),
      axios.get(
        `${STRAPI_API_URL}/api/inspirasis?populate=*&sort=date:desc&fields[0]=title&fields[1]=path&fields[2]=date`,
        {
          headers: {
            Authorization: `Bearer ${STRAPI_TOKEN}`,
          },
        }
      ),
    ]).then((res) => {
      // playlist disney
      let { data } = res[0].data;
      const playlistDisney: TYoutube[] = data.map((e: any) => {
        const { title, description, thumbnail, videoId, customTitle } =
          e.attributes;
        return {
          title: customTitle || title,
          description,
          thumbnails: {
            maxres: {
              url: STRAPI_API_URL + thumbnail.data.attributes.url,
            },
          },
          resourceId: {
            videoId,
          },
        };
      });

      // playlist makeover
      data = res[1].data.data;
      const playlistMakeover: TYoutube[] = data.map((e: any) => {
        const { title, description, thumbnail, videoId, customTitle } =
          e.attributes;
        return {
          title: customTitle || title,
          description,
          thumbnails: {
            maxres: {
              url: STRAPI_API_URL + thumbnail.data[0].attributes.url,
            },
          },
          resourceId: {
            videoId,
          },
        };
      });

      // cat tembok & dekoratif
      data = res[2].data.data;
      const catTembok: TProduct[] = [];
      const catDekoratif: TProduct[] = [];

      data.forEach((e: any) => {
        const { attributes } = e;
        if (attributes.type === "Cat Tembok") {
          catTembok.push({
            title: attributes.title,
            subtitle: attributes.subtitle,
            description: attributes.description,
            url: attributes.url,
            img: STRAPI_API_URL + attributes.img.data.attributes.url,
          });
        } else {
          catDekoratif.push({
            title: attributes.title,
            subtitle: attributes.subtitle,
            description: attributes.description,
            url: attributes.url,
            img: STRAPI_API_URL + attributes.img.data.attributes.url,
          });
        }
      });

      // insp[iration]
      data = res[3].data.data;
      const inspiration: Array<Array<TInspiration>> = [];
      data.forEach((e: any, i: number) => {
        const { title, path, cover, date } = e.attributes;
        const key = data.length >= 6 ? Math.round((i + 1) / 2) - 1 : i;
        if (!inspiration[key]) inspiration[key] = [];
        inspiration[key].push({
          date: new Date(date),
          title: title,
          url: path,
          img: STRAPI_API_URL + cover.data.attributes.url,
        });
      });

      dispatch(
        update({
          ...global,
          // playlistDisney: res[0].data.data,
          playlistDisney,
          // playlistMakeover: res[1].data.data,
          playlistMakeover,
          catDekoratif,
          catTembok,
          inspiration,
        })
      );

      setLoading(false);
    });

    if (global.playlistDisney.length > 0) setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading && window.location.hash) {
      const hash = window.location.hash;
      const el = document.getElementById(hash.split("#")[1]);
      if (el)
        el.scrollIntoView({
          behavior: "smooth",
        });
    }
  }, [loading]);

  if (loading) return <Loader />;

  return (
    <div className="font-open-sans">
      {showModal && (
        <Modal onClose={() => setshowModal(false)}>
          {loadingFrame && (
            <div className="flex items-center justify-center absolute w-[70vw] h-[70vh]">
              <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
            </div>
          )}
          <iframe
            style={{
              height: "70vh",
              width: "70vw",
            }}
            onLoad={() => setloadingFrame(false)}
            src={`https://www.youtube.com/embed/${videoId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; mute"
            allowFullScreen
          ></iframe>
        </Modal>
      )}

      {showBuyModal && (
        <Modal onClose={() => setshowBuyModal(false)}>
          <div className="bg-white w-full h-full flex p-12 gap-4 items-center">
            <div className="flex gap-8 flex-col md:flex-row">
              <div className="flex items-center flex-col md:flex-row">
                {/* <img src="/assets/logo-01.png" width={200} /> */}
                <img src="/assets/tokped-01.png" width={140} />
              </div>
              <div className="flex items-center self-center flex-col gap-2">
                <h3 className="font-bold text-[#303030] mb-4">Beli Sekarang</h3>

                <a
                  className="bg-[#368d2f] text-white p-2 px-4 text-xs cursor-pointer text-center w-full hover:bg-transparent hover:border-[#368d2f] hover:text-[#368d2f] border border-white"
                  href="https://www.tokopedia.com/avianjakarta/etalase/seri-disney"
                  target="_blank"
                >
                  Avian Brands Jakarta
                </a>

                <a
                  className="bg-[#f5c049] text-white p-2 px-4 text-xs cursor-pointer text-center w-full hover:bg-transparent hover:border-[#f5c049] hover:text-[#f5c049] border border-white"
                  href="https://www.tokopedia.com/aviansurabaya/etalase/seri-disney"
                  target="_blank"
                >
                  Avian Brands Surabaya
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <Navbar showBuyModal={() => setshowBuyModal(true)}></Navbar>
      <section>
        <img src="/assets/header-01.jpg" className="w-full" />
        <div className="absolute top-20 text-center text-[80px]"></div>
      </section>

      <section className="flex justify-center text-2xl lg:text-4xl xl:text-6xl p-10 lg:p-15 xl:p-20 bg-[#f5c049]">
        <div className="max-w-[1080px] text-center leading-[34px] lg:leading-[52px] xl:leading-[70px] space-y-10 whitespace-pre-line">
          <div className="whitespace-pre-line">
            Avian Brands menghadirkan <b>#RuangEksplorasi</b>, sebuah wadah
            inspirasi kegiatan DIY berbasis tembok yang mengasah kreativitas
            anak.
          </div>
          <p>
            Jadikan tembok kamar anak terlihat lebih istimewa sebagai kanvas
            kreativitasnya bersama produk-produk cat tembok dan cat dekoratif
            dari <b>Avian Brands Cat Seri Disney.</b>
          </p>
        </div>
      </section>

      <section
        className="bg-[#fdd06a] p-10 lg:p-20 space-y-12"
        id="cat-seri-disney"
      >
        <div className="flex flex-col items-center m-auto space-y-10 max-w-[1440px]">
          <h1 className="text-white text-xl lg:text-5xl bg-[#303030] py-2 px-4 lg:py-4 lg:px-8 rounded-3xl w-fit text-center">
            Cat Tembok
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-4">
            {global.catTembok.map((e, i) => (
              <CatTembok key={i} {...e} />
            ))}
          </div>
        </div>

        <div className="flex flex-col items-center m-auto space-y-10 max-w-[1440px]">
          <h1 className="text-white text-xl lg:text-5xl bg-[#303030] py-2 px-4 lg:py-4 lg:px-8 rounded-3xl w-fit text-center">
            Cat Spesial Efek
          </h1>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-4">
            {global.catDekoratif.map((e, i) => (
              <CatDekoratif key={i} {...e} />
            ))}
          </div>
        </div>
      </section>

      <div
        style={{
          backgroundImage: 'url("/assets/bg-02.jpg")',
        }}
      >
        <IdeDekorasi
          onSelectVideo={(videoId) => {
            setvideoId(videoId);
            setloadingFrame(true);
            setshowModal(true);
          }}
        />

        <Makeover
          onSelectVideo={(videoId) => {
            setvideoId(videoId);
            setloadingFrame(true);
            setshowModal(true);
          }}
        />
      </div>

      <Inspirasi />

      <section
        id="special-package"
        className="py-10 lg:py-20 space-y-6 lg:space-y-12 bg-cover bg-center"
        style={{
          backgroundImage: 'url("/assets/bg-11.jpg")',
        }}
      >
        <div className="flex justify-center">
          <img
            src="/assets/special-package-01.png"
            className="h-auto lg:h-[70vh] w-[70vw] lg:w-auto"
          />
        </div>

        <div className="flex justify-center">
          <img
            src="/assets/special-package-02.png"
            className="h-auto lg:h-[70vh] w-[70vw] lg:w-auto"
          />
        </div>
        {/* <img src="/assets/bg-10.png" className="w-screen" /> */}
      </section>

      <section
        className="text-white p-10 lg:p-20 space-y-6 lg:space-y-12 bg-cover bg-center"
        style={{
          backgroundImage: 'url("/assets/bg-03.jpg")',
        }}
      >
        <h1 className="font-bold text-2xl lg:text-6xl text-center">
          SAVE THE DATE!
        </h1>
        <div
          className="text-4xl text-center p-4 lg:p-12 rounded-[50px] space-y-2 lg:space-y-4 max-w-[1024px] m-auto"
          style={{
            backgroundImage: 'url("/assets/bg-04.png")',
            backgroundPosition: "center",
          }}
        >
          <h3 className="font-bold text-base lg:text-3xl">
            Disney100 Share The Wonder Event
          </h3>
          <h5 className="font-light text-sm lg:text-2xl">
            Mall Kota Kasablanka | 19 Oktober - 5 November 2023
          </h5>
        </div>
      </section>

      <section
        className="text-white p-10 lg:p-20 flex flex-col lg:flex-row items-center justify-center space-x-0 lg:space-x-20 space-y-4"
        style={{
          backgroundImage: 'url("/assets/bg-06.jpg")',
        }}
      >
        <div>
          <h5 className="text lg lg:text-xl">
            Tingkatkan Kreatifitas Anak dengan
          </h5>
          <h1 className=" text-2xl lg:text-6xl font-bold">
            Keajaiban Cat <br />
            Seri Disney
          </h1>
          <div className="space-x-2 flex flex-row mt-4">
            <div className="flex items-center space-x-1">
              <img
                src="/assets/tokped.png"
                className="h-[28px] p-[4px] bg-white"
              />
              <button
                className="bg-white text-black font-bold px-4 py-2 text-xs"
                onClick={() => setshowBuyModal(true)}
              >
                Beli Sekarang
              </button>
            </div>

            {/* <div className="flex items-center space-x-1">
              <img
                src="/assets/tiktok.png"
                className="h-[28px] p-[4px] bg-black"
              />
              <button className="bg-black text-white font-bold px-4 py-2 text-xs">
                Beli Sekarang
              </button>
            </div> */}
          </div>
        </div>

        <img src="/assets/bg-07.png" className="w-[480px]" />
      </section>
    </div>
  );
};
